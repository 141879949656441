<template>
  <v-card>
    <v-progress-linear :active="editando" indeterminate rounded height="6"></v-progress-linear>

    <h1 class="ml-4">{{ formTitle }}</h1>

    <v-card-text>
      <form id="form" enctype="application/x-www-form-urlencoded">

        <input type="hidden" v-model="form.id">
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="form.titulo" :rules="obrigatorio" label="Titulo do documento*"
              @keyup="setURL()"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-4">
            <div style="font-size: 12px;">Anexar documento</div>
            <v-file-input accept="pdf/*" chips class="arquivoAnexo" :label="'Buscar documento...'">
            </v-file-input>
          </v-col>
          <div v-show="anexo" class="mb-5 col-md-6">
            <label style="font-size: 12px;">Documento anexo</label>
            <v-list-item style="padding: 0;">
              <v-list-item-content>
                <v-list-item-title style="cursor: pointer;">
                  <v-icon x-large :color="color" class="mr-0">{{ mimeType }}</v-icon>{{ form.titulo }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-row>

        <v-row>
          <v-col class="col-12 col-md-4">
            <v-text-field label="Link do documento" v-model="form.url" :rules="obrigatorio"
              placeholder="Crie um link para esta página"></v-text-field>
          </v-col>
        </v-row>
      </form>
    </v-card-text>

    <v-row>
      <template v-if="showErro">
        <v-col cols="6">
          <v-alert type="error" elevation="2" class="ml-4" v-show="true">
            <ul>
              <li v-for="(i, indice) in respErro" :key="indice" v-html="i">
              </li>
            </ul>
          </v-alert>
        </v-col>
      </template>
    </v-row>

    <v-card-actions class="pa-6">
      <v-row>
        <v-btn class="mr-1" color="blue darken-1" dark @click="save" :loading="this.carregando">
          Salvar
        </v-btn>

        <v-btn v-if="form.id" color="mr-1 red darken-1" dark @click="removeAnexo(form.id)">
          Excluir
        </v-btn>

        <v-btn color="gray darken-1" dark @click="close">
          Pesquisar
        </v-btn>
      </v-row>
    </v-card-actions>
    <v-dialog v-model="dialogConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">
          {{ message ? message : 'Registro salvo com sucesso!' }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirm">
            Ok
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>
<script>
import api from '@/api'

export default {
  data() {
    return {
      dialogConfirm: false,
      message: '',
      imagem: '',
      anexo: false,
      mimeType: '',
      form: {
        id: '',
        titulo: '',
        url: 'documento/'
      },
      icones: {
        'application/pdf': 'mdi-file-pdf-box',
        'image/png': 'mdi-file-image',
        'image/jpeg': 'mdi-file-image',
        'image/svg+xml': 'mdi-file-image',
        'application/msword': 'mdi-file-document',
        'application/vnd.ms-excel': 'mdi-file-table',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'mdi-file-table'
      },
      color: '',
      colors: {
        'application/pdf': 'red',
        'image/png': 'blue',
        'image/jpeg': 'blue',
        'image/svg+xml': 'blue',
        'application/msword': 'blue',
        'application/vnd.ms-excel': 'green',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'green'
      },
      editando: true,
      carregando: false,
      showErro: false,
      respErro: [],
      usr_cadastro: '',
      usr_alteracao: '',
      dt_cadastro: '',
      dt_alteracao: '',
      valid: false,
      obrigatorio: [
        v => !!v || 'Este campo é obrigatório',
      ],
      regras: [
        v => !v || v.size < 10000000 || 'Avatar size should be less than 2 MB!',
      ],
    }
  },

  computed: {
    formTitle() {
      return this.$route.params.id === undefined || null ? 'Cadastro de Documento' : 'Edição de Documento'
    },
  },
  created() {
    let id = this.$route.params.id;
    if (id != undefined) {
      api.get(`${process.env.VUE_APP_API}/documento/edit/${id}`)//erro
        .then((r) => {
          this.form.id = r.data.id;
          this.form.titulo = r.data.titulo;
          this.form.url = r.data.url;
          this.mimeType = this.icones[r.data.type];
          this.color = this.colors[r.data.type];
          this.usr_cadastro = r.data.usuario_cad;
          this.usr_alteracao = r.data.usuario_alt;
          this.dt_cadastro = r.data.created_at;
          this.dt_alteracao = r.data.updated_at;
          this.dt_publicacao = r.data.dt_publicacao;
          this.anexo = `data:${r.data.type};base64, ${r.data.anexo}`
        })
        .finally(() => this.editando = false)
    } else {
      this.form.id = '';
      this.form.titulo = '';
      this.form.url = 'documento/';

      this.editando = false;
    }
  },

  methods: {
    serialize() {
      let form = document.getElementById('form');
      let formData = new FormData(form);

      for (let campo in this.form) {
        formData.append(campo, this.form[campo] ?? '');
      }

      let uploads = document.querySelectorAll('input[type="file"]');

      uploads.forEach(function (item) {
        let arquivo = item.files[0]
        if (typeof arquivo !== 'undefined') {
          formData.append('anexo', arquivo);
        }
      });


      return formData
    },

    removeAnexo(id) {
      if (confirm('Deseja realmente remover este anexo?')) {
        api.post(`${process.env.VUE_APP_API}/documento/remove-anexo/${id}`, '', {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(() => {
            this.$router.push('/documentos');
          })
          .catch((erro) => {
            this.showErro = true
            this.respErro.push(erro.response.data.errors);
          })
      }
    },

    setURL() {
      // let texto = this.removeAcento(this.form.titulo);
      // texto = texto.replace(/( )+/g, '-');

      const randomHash = Math.random().toString(36).substring(2, 8);
      this.form.url = `documento/${randomHash}`;
    },
    close() {
      this.$router.push('/documentos');
    },

    save() {
      this.carregando = true;

      let url = this.form.id ? 'update' : 'store';

      api.post(`${process.env.VUE_APP_API}/documento/${url}`, this.serialize(), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          this.message = response.data.message;
          this.dialogConfirm = true
        })
        .catch((erro) => {
          if (erro.response.data.errors) {
            let erros = erro.response.data.errors

            this.showErro = true
            this.respErro = [];

            if (erros.titulo) {
              this.respErro.push(erros.titulo[0]);
            }

            if (erros.anexo) {
              this.respErro.push(erros.anexo[0]);
            }

            if (erros.url) {
              this.respErro.push(erros.url[0]);
            }
          }
          else if (erro.response.data.message) {
            let msgErro = erro.response.data.message;
            this.respErro.push(msgErro);
          }

        })
        .finally(() => this.carregando = false)
    },

    closeConfirm() {
      this.dialogConfirm = false
      this.$router.push('/documentos')
    },

    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
      text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
      text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
      text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
      text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
      text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
      return text;
    }

  }
}
</script>

<style>
#imagemM {
  width: 230px;
  height: 250px;
  border-radius: 5px;
}

ul {
  list-style: none;
}

#descricao {
  height: 200px;
}
</style>