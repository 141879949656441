<template>
  <v-card>
    <v-progress-linear :active="carregando" indeterminate rounded height="6"></v-progress-linear>

    <h1 class="ml-4">
      Procurados
      <v-btn style="width: 110px; float: right" color="blue darken-1" dark class="d-none d-sm-flex mr-4 mt-3"
        to="/procurados/create">
        Cadastrar
      </v-btn>
    </h1>
    <br />
    <v-form lazy-validation @submit.prevent>
      <v-row>
        <v-col class="ml-4 col-md-6">
          <v-text-field label="Pesquise e tecle Enter..." v-model="titulo" clearable
            append-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col class="col-md-2">
          <v-btn style="display: none" type="submit" @click="initialize()">Pesquisar</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-card-text>
      <v-data-table :headers="colunas" :items="servicos" light>
        <template v-slot:top>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6">
                Você gostaria de deletar esse item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Cancelar
                </v-btn>

                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon :to="'/procurados/edit/' + item.id" id="icone">
            <v-icon small class="mr-2" color="rgb(77 85 170)">
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-icon small @click="deleteItem(item)" color="rgb(175 43 10)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          Nenhum registro encontrado
        </template>
        <template v-slot:item.url="{ item }">
          <v-btn color="primary" class="text-none ml-n5" dark text target="_blank" @click="copiartexto(item.url)">
            Clique para copiar o link<v-icon>mdi-arrange-send-backward</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.type="{ item }">
          <div>
            {{ item.titulo }}
          </div>
        </template>

      </v-data-table>
    </v-card-text>

    <input type="text" id="area" value="" style="color: white; border: none;">
  </v-card>

</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      icones: {
        'image/png': 'mdi-file-image',
        'image/jpeg': 'mdi-file-image',
        'image/svg+xml': 'mdi-file-image',
      },
      color: {
        'image/png': 'blue',
        'image/jpeg': 'blue',
        'image/svg+xml': 'blue',
      },
      linhaSelecionada: '',
      dialogDelete: false,
      carregando: true,
      colunas: [
        {
          text: 'procurado',
          value: 'type'
        },
        {
          text: 'URL',
          value: 'url',
          sortable: false
        },
        {
          text: 'Criado em',
          value: 'dt_cadastro'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
      ],
      servicos: [],
      titulo: '',
    }
  },
  watch: {
    titulo(novo) {
      if (novo === null) {
        this.titulo = '';
      }
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    copiartexto(textoCopiado) {
      let area = document.getElementById('area');
      area.value = textoCopiado;

      area.select();
      document.execCommand('copy');
    },

    initialize() {
      let titulo = encodeURIComponent(this.titulo);
      api.get(`${process.env.VUE_APP_API}/procurado/grid?titulo=${titulo}`)
        .then(r => {
          this.servicos = r.data;
        })
        .finally(() => this.carregando = false)
    },

    editItem(i) {
      return `/procurados/edit/${i.id}`;
    },

    deleteItem(item) {
      this.linhaSelecionada = item;
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      console.log(this.linhaSelecionada.id)
      api.get(`${process.env.VUE_APP_API}/procurado/delete/${this.linhaSelecionada.id}`)
        .then(() => {
          this.closeDelete()
          this.initialize()
        })
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
}
</script>

<style scoped>
#icone {
  width: 25px;
}
</style>